<template>
	<ion-page>
		<!-- Title toolbar disamping icon hamburger menu -->
		<ion-header :translucent="true">
			<ion-toolbar color="ari">
				<ion-buttons slot="start">
					<ion-back-button
						@click="backPage('/conversation')"
						default-href="/conversation"
						text=""
					></ion-back-button>
				</ion-buttons>
				<ion-title>Informasi Tiket</ion-title>
				<ion-progress-bar
					v-if="store.state.isLoading"
					type="indeterminate"
				></ion-progress-bar>
			</ion-toolbar>
		</ion-header>

		<ion-content
			:fullscreen="true"
			class="ion-padding"
			v-if="ticketInfo != null"
		>
			<!-- <pre>{{ ticketInfo }}</pre> -->
			<div>
				<ion-grid>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Nomor Tiket
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.TICKET_NO }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Perusahaan
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.POLICY_NAME }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Nama Karyawan
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.EMP_NAME }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Pasien
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: <b>{{ ticketInfo.MEMBER_NAME }}</b></ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Status Pasien
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.RELATIONSHIP_NAME_INA }}</ion-col
						>
					</ion-row>
					<ion-row v-if="Dob.value != null">
						<ion-col size-xs="5" size-sm="3">
							Tanggal Lahir
						</ion-col>
						<ion-col size-xs="7" size-sm="9">: {{ Dob.value }}</ion-col>
					</ion-row>
					<ion-row v-if="Dob.age != null">
						<ion-col size-xs="5" size-sm="3">
							Umur
						</ion-col>
						<ion-col size-xs="7" size-sm="9">: {{ Dob.age }} tahun</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Jenis Kelamin
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.GENDER_NAME_INA }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Tinggi Badan
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>:
							{{ parseInt(ticketInfo.MEMBER_TALL) }}
							cm</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Berat Badan
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>:
							{{ parseInt(ticketInfo.MEMBER_WEIGHT) }}
							kg</ion-col
						>
					</ion-row>
					<ion-row v-if="ticketInfo.MEMBER_DESTATIONAL_AGE != null">
						<ion-col size-xs="5" size-sm="3">
							Usia Kandungan
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>:
							{{ parseInt(ticketInfo.MEMBER_DESTATIONAL_AGE) }}
							minggu</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Memiliki Alergi
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>:
							{{
								ticketInfo.MEMBER_ISALLERGY == "True" ? "YA" : "TIDAK"
							}}</ion-col
						>
					</ion-row>
					<ion-row v-if="ticketInfo.MEMBER_ISALLERGY == 'True'">
						<ion-col size-xs="5" size-sm="3">Keterangan Alergi</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.MEMBER_ALLERGY_DESC }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Indikasi
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ ticketInfo.TICKET_INDICATION }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							Obat Dikirim
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ patientAddress.isSend ? "YA" : "TIDAK" }}</ion-col
						>
					</ion-row>
					<ion-row v-if="patientAddress.isSend">
						<ion-col size-xs="5" size-sm="3">
							Alamat Pengiriman
						</ion-col>
						<ion-col size-xs="7" size-sm="9"
							>: {{ patientAddress.address }}</ion-col
						>
					</ion-row>
					<ion-row>
						<ion-col size-xs="5" size-sm="3">
							KTP
						</ion-col>
						<ion-col size-xs="7" size-sm="9" v-if="ticketInfo.MEMBER_KTP_PHOTO"
							>:
							<!-- <ion-chip color="ari" @click="isModalOpen = true"
								>Lihat KTP</ion-chip
							> -->
							<ion-chip color="ari" @click="viewKTP()">Lihat KTP</ion-chip>
						</ion-col>
						<ion-col size-xs="7" size-sm="9" v-else>
							: -
						</ion-col>
					</ion-row>
					<!-- responsif button -->
					<ion-row class="sumit-button">
						<ion-col size-xs="2" size-md="4"></ion-col>
						<ion-col size-xs="8" size-md="4">
							<ion-button
								color="arienam"
								expand="block"
								@click="startConsultation()"
							>
								Mulai Konsultasi
							</ion-button>
						</ion-col>
						<ion-col size-xs="2" size-md="4"></ion-col>
					</ion-row>
				</ion-grid>
			</div>
			<!-- modal popup image -->
			<!-- <ion-modal
				backdrop-dismiss="false"
				:is-open="isModalOpen"
				css-class="previewKTP"
			>
				<div>
					<ion-header>
						<ion-toolbar>
							<div class="infoImage">
								<span>( Dobule click to zoom )</span>
								<a :href="ticketInfo.MEMBER_KTP_PHOTO" target="_blanck">
									download</a
								>
							</div>
							<ion-buttons slot="end">
								<ion-chip @click="isModalOpen = !isModalOpen">Close</ion-chip>
							</ion-buttons>
						</ion-toolbar>
					</ion-header>
					<ion-slides :options="slideOpts">
						<ion-slide>
							<div class="swiper-zoom-container" data-swiper-zoom="3">
								<img class="imgPopup" :src="ticketInfo.MEMBER_KTP_PHOTO" />
							</div>
						</ion-slide>
					</ion-slides>
				</div>
			</ion-modal> -->
			<!-- END modal popup image -->
			<!-- <pre>{{ ticketInfo }}</pre> -->
		</ion-content>
		<ion-toast
			position="top"
			color="danger"
			:is-open="toastFailed.value"
			:message="toastFailed.message"
			:duration="3000"
			@didDismiss="toastFailed.value = false"
		>
		</ion-toast>
		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonButton,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonGrid,
	IonRow,
	IonCol,
	IonBackButton,
	IonProgressBar,
	// IonModal,
	IonChip,
	onIonViewWillEnter,
	onIonViewDidLeave,
	IonToast,
	// IonSlides,
	// IonSlide,
} from "@ionic/vue";
import { personCircleOutline } from "ionicons/icons";
import { inject, ref, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BottomTabBar from "@/components/BottomTabBar.vue";
import { formatDob } from "@/store/methods/utils/utils";
export default {
	name: "TicketInformation",
	components: {
		IonButtons,
		IonButton,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonGrid,
		IonRow,
		IonCol,
		IonBackButton,
		IonProgressBar,
		BottomTabBar,
		// IonModal,
		IonChip,
		IonToast,
		// IonSlides,
		// IonSlide,
	},
	setup() {
		const store = inject("store");
		const route = useRoute();
		const router = useRouter();
		const isOpenRef = ref(false);
		const isModalOpen = ref(false);

		const ticketNo = computed(() => route.params.id);
		var ticketInfo = ref(null);
		var patientName = ref(null);
		const patientAddress = reactive({
			isSend: false,
			address: null,
			lat: null,
			lng: null,
		});
		var doctorGreeting = ref(null);
		const toastFailed = reactive({
			value: false,
			message: "error",
		});
		const setOpen = (state) => (isOpenRef.value = state);
		var Dob = reactive({
			value: null,
			age: null,
		});
		const slideOpts = {
			zoom: {
				maxRatio: 5,
			},
		};
		function viewKTP() {
			router.push(
				`/view-image?url=${ticketInfo.value.MEMBER_KTP_PHOTO}&parentRoute=Conversation`
			);
		}
		function backPage(page) {
			router.replace(page);
		}

		//get information of ticket
		async function getTicketInformation() {
			store.state.isLoading = true;
			const r = await store.ticket.ticketInformation(ticketNo.value);
			//console.log("ticket info", r);
			if (r.status === 200 && r.data.ResponseCode == "000") {
				ticketInfo.value = r.data;
				//save greeting to localStorage
				// localStorage.setItem("doctorGreeting", r.data.GREETING);
				// localStorage.setItem("doctorPatientChatName", r.data.MEMBER_NAME);
				patientName.value = r.data.MEMBER_NAME;
				doctorGreeting.value = r.data.GREETING;
				//set new dob
				Dob.value = formatDob(r.data.MEMBER_DOB);
				//set current Age
				let ageDob =
					new Date().getFullYear() - new Date(r.data.MEMBER_DOB).getFullYear();
				let monthDob = new Date(r.data.MEMBER_DOB).getMonth();
				let monthNow = new Date().getMonth();
				let currentAge = monthDob > monthNow ? ageDob - 1 : ageDob;
				Dob.age = currentAge;

				//set patient address if any
				if (r.data.ISSEND_MEMBER_MEDICINE === "True") {
					patientAddress.isSend = true;
					patientAddress.address = r.data.TICKET_MEMBER_ADDRESS;
					patientAddress.lat = r.data.TICKET_MEMBER_LATTITUDE || "";
					patientAddress.lng = r.data.TICKET_MEMBER_LONGITUDE || "";
				} else {
					patientAddress.isSend = false;
				}
			}
			store.state.isLoading = false;
		}

		onIonViewWillEnter(() => {
			getTicketInformation();
		});
		onIonViewDidLeave(() => {
			ticketInfo.value = null;
		});

		//start consultation at first time
		async function startConsultation() {
			store.state.isLoading = true;
			const r = await store.ticket.doctorStartConsultation(ticketNo.value);
			if (r.status === 200 && r.data.ResponseCode == "000") {
				store.state.isLoading = false;
				//clear state before chat
				store.chat.clearStateChat();
				store.state.totalNewTicket = parseInt(store.state.totalNewTicket) - 1;
				router.replace(
					"/active-chat/" +
						ticketNo.value +
						`?patientName=${encodeURIComponent(
							patientName.value
						)}&doctorGreeting=${encodeURIComponent(doctorGreeting.value)}`
				);
				//then update list ticket for real time
				//store.ticket.listRequestConsultation();
				//store.ticket.listStartConsultation();
			} else {
				store.state.isLoading = false;
				store.chat.clearStateChat();
				toastFailed.value = true;
				toastFailed.message = r.data.ResponseMessage;
				setTimeout(() => {
					router.replace("/conversation");
				}, 3100);
				return;
			}
		}

		// //to continue consultation
		// function continueConsultation() {
		// 	//clear state before chat
		// 	store.chat.clearStateChat();
		// 	router.replace("/active-chat/" + ticketNo);
		// }

		return {
			Dob,
			route,
			store,
			personCircleOutline,
			isOpenRef,
			isModalOpen,
			ticketInfo,
			setOpen,
			startConsultation,
			slideOpts,
			//method
			viewKTP,
			toastFailed,
			backPage,
			patientName,
			patientAddress,
		};
	},
};
</script>

<style scoped>
ion-item {
	cursor: pointer;
}
.bg-information {
	background-color: red;
}
.personIcon {
	color: #1a2674;
	margin-right: 10px;
	font-size: 40px;
}
.imgPopup {
	margin: 0 auto;
	width: 100%;
	height: auto;
}
</style>
